import React from "react"
import queryString from "query-string"
import {useStaticQuery, graphql} from "gatsby"

import Card from "../../components/card"
import { FormFieldInput, FormFieldTextArea  } from "../../components/form-fields"

import ApplicationFormLogic from "../../components/application-form-logic"
import ApplicationFormContainer from "../../components/application-form-container"

export class DriehausImpactAwardForm extends ApplicationFormLogic {
  render() {
    return (
      <ApplicationFormContainer
        data={this.props.data}
        state={this.state}
        closeModal={this.closeModal}
        handleSubmit={this.handleSubmit}
        handleSave={this.handleSave}
        >
        <Card sectionTitle="Nominee" sectionSubtitle="">
          <FormFieldInput
            labelText="Name of nominee"
            name="nomineeName"
            type="text"
            value={this.state.nomineeName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Affiliation of nominee"
            name="nomineeAffiliation"
            value={this.state.nomineeAffiliation}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Email of nominee"
            name="nomineeEmail"
            type="text"
            value={this.state.nomineeEmail}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldTextArea
            labelText="Please provide a brief bio of the nominee."
            name="nomineeBio"
            type="text"
            value={this.state.nomineeBio}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldTextArea
            labelText="Please provide a brief overview of why you are making this nomination - including detail how the nominee meets the criteria for the award."
            name="nomineeOverview"
            type="text"
            value={this.state.nomineeOverview}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />
        </Card>

        <Card sectionTitle="Contact" sectionSubtitle="">
          <FormFieldInput
            labelText="Name of person completing this survey"
            name="applicantName"
            type="text"
            value={this.state.applicantName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Email of person completing this survey"
            name="applicantEmail"
            type="text"
            value={this.state.applicantEmail}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="How did you learn about the opportunity to apply for this award?"
            name="applicantReferral"
            type="text"
            value={this.state.applicantReferral}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />
        </Card>
      </ApplicationFormContainer>
    );
  }
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "applications/driehaus-impact-award.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 400, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  data.applicationType="driehaus-impact-award"
  data.fields=[
    'nomineeName', 'nomineeAffiliation', 'nomineeEmail', 'nomineeBio', 
    'nomineeOverview', 'applicantName', 'applicantEmail', 'applicantReferral'
  ]

  data.title="Driehaus Impact Award"
  data.titleText="The Richard H. Driehaus Foundation Community Design Impact Award"
  data.awardDescription="The Richard H. Driehaus Foundation Community Design Impact Award recognizes an emerging architect of color who is engaged in community design in Chicago's low-to-moderate income neighborhoods and/or with projects that serve communities/populations that have experienced disinvestment as part of their portfolio of work."
  data.awardLink="https://lisc-cnda.org/awards/driehaus-impact-award"
  data.headerImagePath="applications/driehaus-impact-award.jpg"

  return (
    <DriehausImpactAwardForm
      data={data}
      uuid={queryString.parse(props.location.search).uuid}
  />
  )
}
